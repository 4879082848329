$color-4: #ffffff;
$color-3: #ffffff;
$color-2: #ffffff;
$color-1: #ffffff;
$color-5: #ffffff;

$color: $color-1, $color-2, $color-3, $color-4, $color-5;

@mixin anim() {
  @keyframes scale {
    0% { transform: scale(1); }
    50%, 75% { transform: scale(2.5); }
    78%, 100% { opacity: 0; }
  }
}

#app-loading + body {
  background: #4e54c8;
}

#app-loading {
  background: #4e54c8;  /* fallback for old browsers */;
  background: -webkit-linear-gradient(to right, #4e54c8, #8f94fb); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4e54c8, #8f94fb); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @keyframes loading-opacity-anim {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
    animation-delay: 1s;

    @for $i from 1 through 5 {
      .item-#{$i} {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        @include anim();
        background-color: nth($color, $i);
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: nth($color, $i);
          opacity: 0.7;
          animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
          animation-delay: 200ms * $i;
          transition: 0.5s all ease;
          transform: scale(1);
        }
      }
    }
  }
}