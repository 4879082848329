.page-header-image {
    z-index: -1;
    width: 100%;
    height: 400px;

    position: absolute;
    background-size: cover;
    background-position: 50%;
    background-position-x: 50%;
    background-position-y: center;

    -webkit-filter: brightness(0.4);
        -ms-filter: fade(0.4);
            filter: brightness(0.4);
}

.contacts-content {
    margin-top: 100px;
}

#contact-form * { color: #FFFFFF !important; }
#contact-form *::placeholder { color: #aaaaaa !important;  }

.infos .info {
    padding-top: 10% !important;
    padding-bottom: 5% !important;
}

.infos .info,
.contacts .contact-title,
.contacts .contact-subtitle {
    text-align: center;
}

.contacts .contact-title {
    padding-top: 160px;
    color: #ffffff;

    font-size: 52pt;
    font-weight: bold;
}
.contacts .contact-subtitle {
    font-size: 18pt;
}

@media screen and (max-width: 600px) {
    .contacts .contact-title {
        padding-top: 100px;
        font-size: 10vw;
    }
    .contacts .contact-subtitle {
        font-size: 4vw;
    }

    .page-header-image {
        height: 250px;
    }
}